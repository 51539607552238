import { Text as ChakraText, HeadingProps } from '@chakra-ui/react';

import React from 'react';

const lineHeightAndLetterSpacingProps = (props) => {
  if (props.fontSize === 'lg') {
    // Body 1
    return {
      lineHeight: '150%',
      letterSpacing: '-0.015em',
    };
  } else if (props.fontSize === 'sm') {
    // Body 4
    return {
      lineHeight: '130%',
      letterSpacing: '-0.01em',
    };
  } else if (props.fontSize === 'xs') {
    // Label
    return {
      lineHeight: '130%',
    };
  } else {
    // Body 3
    if (props.fontWeight === 600) {
      return {
        lineHeight: '130%',
        letterSpacing: '-0.015em',
      };
    } else {
      return {
        lineHeight: '140%',
        letterSpacing: '-0.015em',
      };
    }
  }
};

const Text: React.FC<HeadingProps> = React.forwardRef(({ children, ...props }: HeadingProps, ref) => {
  return (
    <ChakraText {...lineHeightAndLetterSpacingProps(props)} {...props} {...ref}>
      {children}
    </ChakraText>
  );
});

export default Text;
